<template>
  <div class="Login">
    <img
      class="Logo"
      src="@/assets/svg/logos/milva-logo-green.svg" />
    <span class="LoginText">{{ mixWB('SIGN_IN_TO_YOUR_ACCOUNT') }}</span>
    <div class="LoginFormWrap">
      <LoginForm v-if="!showForgotPassword" />
      <ForgotPasswordForm v-else />
      <Button
        size="small"
        color="link"
        :fullWidth="false"
        :text="showForgotPassword ? mixWB('LOGIN_LINK') : mixWB('FORGOT_PASSWORD_LINK')"
        @button-click="toggleForgotPassword" />
    </div>

    <div class="LangSelectWrap">
      <span>{{ mixWB('SELECT_LANGUAGE') }}</span>
      <div class="Options">
        <div
          v-for="lang in languages"
          :key="lang.locale"
          class="Item"
          :class="{ IsActive: lang.locale === selectedLanguage.locale }"
          @click="onLangClick(lang)">
          <span>{{ lang.countryCode }}</span>
        </div>
      </div>
    </div>

    <!-- Version -->
    <span class="PackageVersion">{{ getPackageVersionText() }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EventBus from '@/EventBus'
import LoginForm from '@/components/Forms/LoginForm.vue'
import ForgotPasswordForm from '@/components/Forms/ForgotPasswordForm.vue'
import Button from '@/components/Buttons/Button.vue'
import { keys } from '@/globals/javascript/_util/keys'

export default {
  name: 'Login',
  data() {
    return {
      showForgotPassword: false,
    }
  },
  computed: {
    ...mapGetters([
      'packageVersion',
      'selectedLanguage',
      'languages',
    ]),
  },
  methods: {
    onUserLogin() {
      this.$router.push({ name: 'AllScreenings' })
    },
    onLangClick(lang) {
      window.localStorage.setItem(keys.LS_SAVED_LOCALE, lang.locale)
      window.location.reload()
    },
    getPackageVersionText() {
      return `${ this.mixWB('VERSION_X', [this.packageVersion]) } @ ${ process.env.NODE_ENV }`
    },
    toggleForgotPassword() {
      this.showForgotPassword = !this.showForgotPassword
    },
  },
  components: {
    LoginForm,
    ForgotPasswordForm,
    Button,
  },
  created() {
    EventBus.$on('user-logged-in', this.onUserLogin)
  },
  destroyed() {
    EventBus.$off('user-logged-in', this.onUserLogin)
  },
}
</script>

<style lang="stylus" scoped>
  .LoginFormWrap
    width 100%
    max-width 80%
    margin-bottom 30px
  .Login
    width 100%
    height 100vh
    display flex
    flex-direction column
    align-items center
    padding-top 100px

  .Logo
    max-width 150px
    margin-bottom 30px
    margin-left 30px

  .LoginText
    margin-bottom 30px

  .LangSelectWrap
    display block
    text-align center
    .Options
      flex-center-children()
    .Item
      margin 5px
      border 1px solid $color_grey_lighter
      padding 10px 20px
      cursor pointer
      &.IsActive
        background-color $color_salmon_light
        border 1px solid $color_salmon_light
        span
          color #fff

  .PackageVersion
    margin-top 20px
    font-size 0.875rem
    line-height 1
    color $color_grey

</style>

<style lang="stylus">
  .PageSignIn
    padding-bottom 0
</style>
